import React, { createContext, useMemo, useState } from 'react';

export const WorkInProgressContext = createContext();

export const WorkInProgressProvider = ({ children }) => {
    const [showWorkInProgressModal, setShowWorkInProgressModal] = useState(false);

    const value = useMemo(() => {
        return {
            showWorkInProgressModal,
            setShowWorkInProgressModal,
        };
    }, [showWorkInProgressModal]);

    return <WorkInProgressContext.Provider value={value}>{children}</WorkInProgressContext.Provider>;
};
