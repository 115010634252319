import parsePhoneNumber from 'libphonenumber-js';

import { config } from '../config';
import LOCAL_STORAGE_KEYS from '../enums/localStorageKeys';
import { versionUpdateNeededVar } from '../lib/apollo/cache';

export const getPhoneNumberNationalFormat = (phone: string) => {
    try {
        if (typeof phone === 'string') {
            const phoneNumber = parsePhoneNumber(phone);
            if (phoneNumber) {
                return phoneNumber.formatInternational();
            }
        } else {
            return null;
        }
    } catch (err: any) {
        return phone;
    }
    return phone;
};

export const isDefinedAndNumber = (value?: string | number | null) => {
    if (value) {
        const numberValue = Number(value);
        return !isNaN(numberValue);
    }
    return false;
};

export const shareLectureWithEmail = (mailto: string) => {
    window.location.href = mailto;
};

export const shareLectureOnFacebook = (url: string, winWidth: number, winHeight: number) => {
    const winTop = window.screen.height / 2 - winHeight / 2;
    const winLeft = window.screen.width / 2 - winWidth / 2;
    window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        'sharer',
        `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`,
    );
};

export const shareLectureOnWhatsapp = (url: string, text: string, winWidth: number, winHeight: number) => {
    const winTop = window.screen.height / 2 - winHeight / 2;
    const winLeft = window.screen.width / 2 - winWidth / 2;
    window.open(
        `https://wa.me/?text=${encodeURIComponent(`${text} (${url})`)}`,
        'sharer',
        `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`,
    );
};

export const checkXVersionMatchCacheBusting = (xVersion?: string | number | number) => {
    if (config.RELEASE_VERSION && isDefinedAndNumber(xVersion) && Number(xVersion) > Number(config.RELEASE_VERSION)) {
        // if server version newer
        localStorage.setItem(LOCAL_STORAGE_KEYS.VERSION_UPDATE_NEEDED, 'true'); // set version update item so can refresh app later
        versionUpdateNeededVar(true);
    }
};
