import React from 'react';
import { Redirect } from 'react-router-dom';

import { APPLICATION_SUBDOMAIN } from '../../constants';

export const ErrorForbidden = () => {
    /*
    https://www.google.com/search?q=funny+design+forbidden+error+page&tbm=isch&ved=2ahUKEwjz1Nntlc7yAhVEM-wKHa9sBUgQ2-cCegQIABAA&oq=funny+design+forbidden+error+page&gs_lcp=CgNpbWcQA1DvJFi0LWD8LmgAcAB4AIABQ4gBlQSSAQE5mAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=f0MnYbOZN8TmsAev2ZXABA&bih=1304&biw=2560&rlz=1C5CHFA_enCZ915CZ915#imgrc=ygz8KrHBkCEWxM
     */
    return <Redirect to={`/${APPLICATION_SUBDOMAIN}`} />;
};
