import { FormatNumberOptions } from '@formatjs/intl';
import { currency as currencyType } from '@fyooga/codebook';
import { getParamByParam } from 'iso-country-currency';

import { makeFormatter } from '../makeFormatter';
import { FormatterConfig } from '../types';

export type FiatAmountFormatterDataContext<T> = {
    [K in keyof T]: T[K];
};

export interface FiatDataContext extends Omit<FormatNumberOptions, 'currency'> {
    currency: string; // make currency required
    withValue?: boolean;
    withCurrencyCode?: boolean;
}

export const prepareFiatAmountFormatter = (config: FormatterConfig) =>
    makeFormatter<string | number, string, FiatAmountFormatterDataContext<FiatDataContext>>((value, dataContext) => {
        const { intl } = config;
        const { style, currency, withValue = true, withCurrencyCode = false } = dataContext;
        let { minimumFractionDigits, maximumFractionDigits } = dataContext;

        if (Number.isNaN(value)) {
            return '';
        }

        // country specific
        if (minimumFractionDigits) {
            minimumFractionDigits = minimumFractionDigits;
        } else {
            minimumFractionDigits = currency === 'CZK' || currency === currencyType.CREDITS ? 0 : minimumFractionDigits;
        }
        maximumFractionDigits = currency === 'CZK' || currency === currencyType.CREDITS ? 0 : maximumFractionDigits;

        if (withValue) {
            return intl.formatNumber(Number(value), {
                ...dataContext,
                style: style || 'currency',
                currency: currency,
                minimumFractionDigits: typeof minimumFractionDigits === 'undefined' ? 2 : minimumFractionDigits,
                maximumFractionDigits: typeof maximumFractionDigits === 'undefined' ? 2 : maximumFractionDigits,
            });
        }

        try {
            if (withCurrencyCode) {
                return `${currency} (${getParamByParam('currency', currency!, 'symbol')})`;
            }
            return getParamByParam('currency', currency!, 'symbol');
        } catch {
            return '';
        }
    });
