import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollToTopProps {
    children: ReactNode;
}

export const ScrollToTop = ({ children }: ScrollToTopProps) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{children}</>;
};
