import i18n from '../../i18n';
import { makeFormatter } from '../makeFormatter';
import { FormatterConfig } from '../types';

export type EntryFormatterDataContext = {
    type: 'credit' | 'pass' | 'make_up_session';
    withValue?: boolean;
};

export const prepareEntryFormatter = (config: FormatterConfig) =>
    makeFormatter<string | number, string, EntryFormatterDataContext>((value, dataContext) => {
        const { intl } = config;
        const { type, withValue = true } = dataContext;

        if (Number.isNaN(value)) {
            return '';
        }

        let pluralType;

        if (type === 'credit') {
            pluralType = 'credit';
        } else if (type === 'pass') {
            pluralType = 'entryPass';
        } else if (type === 'make_up_session') {
            pluralType = 'makeUpSession';
        }

        const currencyWithTranslation = i18n.t(`plurals.${pluralType ?? ''}`, {
            postProcess: 'interval',
            count: Number(value),
        });

        return `${withValue ? `${value} ` : ''}${currencyWithTranslation}`;
    });
