import { useReactiveVar } from '@apollo/client';
import React, { ReactNode, useEffect } from 'react';

import { isForbiddenErrorVar, isNetworkErrorVar, isUnauthorizedErrorVar } from '../lib/apollo/cache';
import i18n from '../lib/i18n';
import { showErrorToast } from '../lib/toasts';
import { ErrorForbidden, ErrorNetworkProblems } from '../pages/errors';

export const ContentHandler = ({ children }: { children: ReactNode }) => {
    const isNetworkError = useReactiveVar(isNetworkErrorVar);
    const isForbiddenError = useReactiveVar(isForbiddenErrorVar);
    const isUnauthorizedError = useReactiveVar(isUnauthorizedErrorVar);

    useEffect(() => {
        if (isUnauthorizedError) {
            showErrorToast(i18n.t(`errorNs:loggedOutForSecurityReason`));
        }
    }, [isUnauthorizedError]);

    if (isNetworkError) {
        return <ErrorNetworkProblems refresh={false} />;
    }

    if (isForbiddenError) {
        return <ErrorForbidden />;
    }

    return <>{children}</>;
};
