import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ContentLoading } from '../components/ui/ContentLoading';
import { APPLICATION_SUBDOMAIN } from '../constants';
import { ErrorNotFound } from '../pages/errors';
import { lazyRetry } from '../util/codeSplitting';

const CommonRoutesSwitch = React.lazy(() => lazyRetry(() => import('./CommonRoutesSwitch'), 'commonRoutesSwitch'));
const AppRouter = React.lazy(() => lazyRetry(() => import('./AppRouter/AppRouter'), 'appRouter'));

export const AuthorizedAppRouter = () => {
    return (
        <React.Suspense fallback={<ContentLoading />}>
            <Switch>
                <Route path={`/${APPLICATION_SUBDOMAIN}`}>
                    <AppRouter />
                </Route>
                <CommonRoutesSwitch />
                <Route path="*">
                    <ErrorNotFound />
                </Route>
            </Switch>
        </React.Suspense>
    );
};
