import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface LinkTextProps {
    title?: string;
    to?: string;
    children?: ReactNode;
    targetBlank?: boolean;
    tooltip?: boolean;
}

const LinkTextStyle = styled.a<{ tooltip: boolean }>`
    ${({ tooltip }) => {
        if (tooltip) {
            return `
                color: #e99b02;
                text-decoration: underline;
            
                &:hover {
                    color: #e99b02;
                    text-decoration: none;
                }
            `;
        }
    }}
`;

export const LinkText = ({ to, title, children, targetBlank = false, tooltip = false }: LinkTextProps) => {
    return (
        <LinkTextStyle tooltip={tooltip} href={to || '#'} target={targetBlank ? '_blank' : '_self'} title={title || ''}>
            {children}
        </LinkTextStyle>
    );
};
