import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppLocale } from '../hooks/apollo/useAppLocale';
import { AddressT } from '../types/address';
import { getCountryNameByCode } from '../util/countries';

const AddressOutputContainer = styled.div<{ marginBottom?: number }>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px` ?? 0};
`;

export const AddressOutput = ({
    address,
    marginBottom,
    withCompanyInfo = false,
}: {
    address: AddressT;
    marginBottom?: number;
    withCompanyInfo?: boolean;
}) => {
    const { t } = useTranslation();
    const userAppLocale = useAppLocale();

    return (
        <AddressOutputContainer marginBottom={marginBottom}>
            <div>{address?.street}</div>
            <div>
                {address?.city}
                {address?.postalCode && `, ${address.postalCode}`}
            </div>
            <div>{getCountryNameByCode(address?.country, userAppLocale)}</div>
            {withCompanyInfo && address.companyIdentificationNumber && (
                <div>
                    {t('settingsNs:addressForm.companyIdentificationNumber')}: {address.companyIdentificationNumber}
                </div>
            )}
            {withCompanyInfo && address.vatNumber && (
                <div>
                    {t('settingsNs:addressForm.vatNumber')}: {address.vatNumber}
                </div>
            )}
        </AddressOutputContainer>
    );
};
