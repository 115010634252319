import React from 'react';
import styled from 'styled-components';

import arrowShape from '../../assets/images/calendar/squareBracket.svg';

const CaretShape = styled.span`
    display: inline-block;
    height: 34px;
    width: 34px;
    margin-right: 2px;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-position: center;
    transform: ${({ isActive }) => `rotateZ(${isActive ? 270 : 90}deg)`};
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
`;

/*
const CaretShape = styled.span`
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    width: 24px;
    height: 24px;
    text-align: center;
    pointer-events: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-position: center;
`;
 */

export const Caret = ({ isActive = false, dropdownButton = false }) => {
    return <CaretShape image={arrowShape} isActive={isActive} />;
};
