import React, { ReactNode } from 'react';

import { Header, HeaderMobile } from './Header';

export interface HeaderMobileWrapperProps {
    children: ReactNode;
    isMobile: boolean;
}

export const HeaderMobileWrapper = ({ children, isMobile }: HeaderMobileWrapperProps) => {
    if (!isMobile) {
        return <Header>{children}</Header>;
    }

    return <HeaderMobile>{children}</HeaderMobile>;
};
