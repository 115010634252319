import { Layout } from 'antd';
import { ReactNode, useContext } from 'react';

import { PublicOfficeContext } from '../context/PublicOfficeProvider';
import { bgColorLayout } from '../theme';

interface ZenamuLayoutProps {
    children: ReactNode;
}

export const ZenamuLayout = ({ children }: ZenamuLayoutProps) => {
    const contextData = useContext(PublicOfficeContext);

    const officeBackground = contextData?.officeBackground;

    const backgroundStyle = {
        ...(officeBackground !== null && {
            backgroundImage: `url('${officeBackground}')`,
        }),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed' /* keeps the background fixed when scrolling */,
        backgroundColor: bgColorLayout /* a background color as a fallback */,
        transition: 'filter 0.5s ease-out',
    };

    return <Layout style={{ minHeight: '100vh', ...backgroundStyle }}>{children}</Layout>;
};
