import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LoadingIcon } from './LoadingIcon';

interface ContentLoadingProps {
    withDescription?: boolean;
}

export const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const ContentLoading = ({ withDescription = false }: ContentLoadingProps) => {
    const { t } = useTranslation();

    if (!withDescription) {
        return (
            <Loader>
                <LoadingIcon />
            </Loader>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '15px',
                marginBottom: '15px',
            }}
        >
            <div>
                <b>{t('loading')}</b>
            </div>
            <Loader>
                <LoadingIcon />
            </Loader>
        </div>
    );
};
