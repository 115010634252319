import { Editor } from '@tiptap/react';

import i18n from '../lib/i18n';

export const processTipTapLink = (editor: Editor) => {
    const previousUrl = editor.getAttributes('link').href;

    const url = window.prompt(i18n.t('wysiwygEditor.linkDescription'), previousUrl);

    // cancelled
    if (url === null) {
        return;
    }

    // empty
    if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
};
