import { FormatDateOptions } from '@formatjs/intl';

import { makeFormatter } from '../makeFormatter';
import { FormatterConfig } from '../types';

export type DateTimeRangeFormatterDataContext<T> = {
    [K in keyof T]: T[K];
};

export const prepareDateTimeRangeFormatter = (config: FormatterConfig) =>
    makeFormatter<
        {
            from: Date;
            to: Date;
        },
        string,
        DateTimeRangeFormatterDataContext<FormatDateOptions>
    >(({ from, to }, dataContext) => {
        const { intl } = config;

        return intl.formatDateTimeRange(from, to, {
            ...dataContext,
        });
    });
