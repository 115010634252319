import { useQuery, useReactiveVar } from '@apollo/client';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { useMemo } from 'react';

import { userIdVar } from '../lib/apollo/cache';
import { useLoggedIn } from './apollo/useLoggedIn';

export const useHasUserAccessByOfficeRole = (officeId: number, requiredOfficeRoles: string[]) => {
    const isLoggedIn = useLoggedIn();
    const userId = useReactiveVar(userIdVar);

    const { loading: userOfficeEmployerDataLoading, data: userOfficeEmployerData } = useQuery(
        fyoogaQueries.getEmploymentByUserIdAndOfficeId,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                userId,
                officeId,
            },
            skip: !(userId && officeId),
        },
    );

    const hasUserAccessByOfficeRole = useMemo(() => {
        if (!isLoggedIn) {
            return {
                hasAccess: false,
                loading: false,
            };
        }

        if (!userOfficeEmployerDataLoading) {
            if (userOfficeEmployerData?.employmentByUserIdAndOfficeId) {
                const { organisationOffices } = userOfficeEmployerData.employmentByUserIdAndOfficeId;

                const [office] = organisationOffices;

                return {
                    hasAccess: requiredOfficeRoles.includes(office?.role),
                    loading: false,
                };
            } else {
                return {
                    hasAccess: false,
                    loading: false,
                };
            }
        }

        return {
            hasAccess: false,
            loading: true,
        };
    }, [requiredOfficeRoles, userOfficeEmployerData, userOfficeEmployerDataLoading]);

    return hasUserAccessByOfficeRole;
};
