import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import { LanguageSwitcher } from '../components/LanguageSwitcher';
import { ContentHandler } from './ContentHandler';
import { FooterContainer } from './FooterContainer';
import { PublicHeader } from './headers/PublicHeader';

const { Footer, Content } = Layout;

export const PublicLayoutComponent = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <PublicHeader />
            <ContentHandler>
                <Content>{children}</Content>
            </ContentHandler>
            <Footer>
                <FooterContainer isPublic />
            </Footer>
        </>
    );
};
