import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BubbleMenu } from '@tiptap/react';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { processTipTapLink } from '../../../util/tipTapEditor';

export const BubbleMenuComponent = ({ editor }) => {
    if (!editor) {
        return null;
    }

    const setLink = useCallback(() => {
        processTipTapLink(editor);
    }, [editor]);

    return (
        <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
                <FontAwesomeIcon icon="heading" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={classNames('bubble-button', editor.isActive('bold') && 'is-active')}
            >
                <FontAwesomeIcon icon="bold" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={classNames('bubble-button', editor.isActive('italic') && 'is-active')}
            >
                <FontAwesomeIcon icon="italic" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={classNames('bubble-button', editor.isActive('strike') && 'is-active')}
            >
                <FontAwesomeIcon icon="strikethrough" />
            </button>
            <button
                type="button"
                onClick={setLink}
                className={classNames('bubble-button', editor.isActive('link') && 'is-active')}
            >
                <FontAwesomeIcon icon="link" />
            </button>
        </BubbleMenu>
    );
};
