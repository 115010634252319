import { useQuery, useReactiveVar } from '@apollo/client';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { useMemo } from 'react';

import { userIdVar } from '../../lib/apollo/cache';
import { GET_EMPLOYEE_CURRENT_HANDLED_OFFICE } from '../../lib/apollo/localQueries';
import { getAllEmployeeOfficesAccessArray } from '../../util/office';

export const useEmployeeCurrentHandledOfficeData = (officeId?: number): any => {
    const { data } = useQuery(GET_EMPLOYEE_CURRENT_HANDLED_OFFICE);

    const userId = useReactiveVar(userIdVar);

    const { loading: userOfficeEmployerDataLoading, data: userOfficeEmployerData } = useQuery(
        fyoogaQueries.getEmploymentByUserIdAndOfficeId,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                userId,
                officeId,
            },
            skip: !(userId && officeId),
        },
    );

    const employeeCurrentHandledOffice = useMemo(() => {
        if (!officeId) {
            return data?.employeeCurrentHandledOffice;
        }
        // To prevent undefined in organisationEmployeeId: employeeCurrentHandledOfficeData.organisationEmployeeId, for CancelLectureModal e.g. when it is canceled from little calendar
        if (
            !userOfficeEmployerDataLoading &&
            userOfficeEmployerData?.employmentByUserIdAndOfficeId &&
            !data?.employeeCurrentHandledOffice
        ) {
            const { allEmployeeOfficesAccessArray } = getAllEmployeeOfficesAccessArray([
                userOfficeEmployerData.employmentByUserIdAndOfficeId,
            ]);

            const [office] = allEmployeeOfficesAccessArray;

            return office;
        }
        return data?.employeeCurrentHandledOffice;
    }, [officeId, data, userOfficeEmployerDataLoading, userOfficeEmployerData]);

    return employeeCurrentHandledOffice;
};
