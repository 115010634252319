import { FormatDateOptions } from '@formatjs/intl';

import { makeFormatter } from '../makeFormatter';
import { FormatterConfig } from '../types';

export type TimeFormatterDataContext<T> = {
    [K in keyof T]: T[K];
};

export interface TimeDataContext extends FormatDateOptions {}

export const prepareTimeFormatter = (config: FormatterConfig) =>
    makeFormatter<Date, string, TimeFormatterDataContext<TimeDataContext>>((dateTime, dataContext) => {
        const { intl } = config;
        const { hour, minute } = dataContext;

        return intl.formatTime(dateTime, {
            hour: hour ?? 'numeric',
            minute: minute ?? 'numeric',
        });
    });
