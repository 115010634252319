import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/ui/Page';

export const ErrorNotFoundStudio = () => {
    const { t } = useTranslation();
    /*
    https://www.google.com/search?q=funny+design+error+page&rlz=1C5CHFA_enCZ915CZ915&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjG4O2xlM7yAhVL_rsIHWmDCjkQ_AUoAXoECAEQAw&biw=2560&bih=1304#imgrc=xGw98vfWv_CwuM
    https://i.pinimg.com/originals/2c/d5/f9/2cd5f95facd32432677a2a7f75391468.jpg
     */
    return (
        <Page>
            <p>{t('errorNs:errorPage.notFoundStudio1')}</p>
        </Page>
    );
};
