import { UnlockOutlined } from '@ant-design/icons';
import { officeUserRoles } from '@fyooga/codebook';
import React, { ReactNode } from 'react';

import history from '../../history';
import { useHasUserAccessByOfficeRole } from '../../hooks/useHasUserAccessByOfficeRole';
import { InteractiveButton } from '../buttons';

export interface TariffSubscriptionInfoProps {
    extendButtonText: string;
    officeId: number;
    domain?: string;
    isFreeTariff?: boolean;
    linkTo: string;
    icon?: ReactNode;
}

export const TariffSubscriptionInfo = ({
    extendButtonText,
    officeId,
    domain,
    isFreeTariff = false,
    linkTo,
    icon = <UnlockOutlined />,
}: TariffSubscriptionInfoProps) => {
    const {
        hasAccess: canUserAccessSettingsForSuperadminRole,
        loading: loadingCanUserAccessSettingsForSuperadminRole,
    } = useHasUserAccessByOfficeRole(officeId, [officeUserRoles.SUPERADMIN]);

    if (loadingCanUserAccessSettingsForSuperadminRole) {
        return null;
    }

    return (
        <>
            {canUserAccessSettingsForSuperadminRole && (
                <div className="expired-subscription-overview-mobile-button">
                    <InteractiveButton
                        icon={icon}
                        type="link"
                        onClick={() => history.push(linkTo)}
                        value={extendButtonText}
                    />
                </div>
            )}
        </>
    );
};
