import { ApolloLink, Observable } from '@apollo/client/core';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';

const createRestartableClient = options => {
    let restartRequested = false;
    let restart = () => {
        restartRequested = true;
    };

    const client = createClient({
        ...options,
        on: {
            ...options.on,
            opened: socket => {
                options.on?.opened?.(socket);

                restart = () => {
                    if (socket.readyState === WebSocket.OPEN) {
                        // if the socket is still open for the restart, do the restart
                        socket.close(4205, 'Client Restart');
                    } else {
                        // otherwise the socket might've closed, indicate that you want
                        // a restart on the next opened event
                        restartRequested = true;
                    }
                };

                // just in case you were eager to restart
                if (restartRequested) {
                    restartRequested = false;
                    restart();
                }
            },
        },
    });

    return {
        ...client,
        restart: () => restart(),
    };
};

/**
 * See more https://github.com/enisdenjo/graphql-ws
 */
export class WebSocketLink extends ApolloLink {
    constructor(clientOptions) {
        super();
        // // all subscriptions from `client.subscribe` will resubscribe after `client.restart`
        this.client = createRestartableClient(clientOptions);
    }

    request(operation) {
        return new Observable(sink => {
            return this.client.subscribe(
                { ...operation, query: print(operation.query) },
                {
                    next: sink.next.bind(sink),
                    complete: sink.complete.bind(sink),
                    // error: sink.error.bind(sink),
                    error: err => {
                        if (err instanceof Error) {
                            return sink.error(err);
                        }

                        if (err instanceof CloseEvent) {
                            return sink.error(
                                // reason will be available on clean closes
                                new Error(`Socket closed with event ${err.code} ${err.reason || ''}`),
                            );
                        }

                        // API is down
                        if (err instanceof Event && err?.currentTarget?.readyState === WebSocket.CLOSED) {
                            return;
                        }

                        return sink.error(new Error(err.map(({ message }) => message).join(', ')));
                    },
                },
            );
        });
    }
}
