import { gql } from '@apollo/client';

export const GET_EMPLOYEE_CURRENT_HANDLED_OFFICE = gql`
    query employeeCurrentHandledOffice {
        employeeCurrentHandledOffice @client {
            name
            domain
            image
            organisationId
            officeId
            role
            productTourCompleted
            organisationEmployeeId
            organisationTariff
            registrationDate
        }
    }
`;

export const GET_APP_LOCALE = gql`
    query appLocale {
        appLocale @client
    }
`;

export const GET_IS_LOGGED_IN = gql`
    query isLoggedIn {
        isLoggedIn @client
    }
`;
