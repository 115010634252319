import { reservedFrontendUrls } from '@fyooga/codebook';

import { APPLICATION_SUBDOMAIN } from '../constants';

export const TRIAL_TARIFF_DAYS_DURATION = 14;

// office
export const SETTINGS_TAB_KEY_GENERAL = 'general';
export const SETTINGS_TAB_KEY_ENTRANCE = 'entrance';
export const SETTINGS_TAB_KEY_RESERVATIONS = 'reservations';
export const SETTINGS_TAB_KEY_PAYMENTS = 'payments';
export const SETTINGS_TAB_KEY_LECTURE_TEMPLATES = 'lecture-templates';
export const SETTINGS_TAB_KEY_INSTRUCTORS = 'instructors';
export const SETTINGS_TAB_KEY_PLACES = 'places';
export const SETTINGS_TAB_KEY_TERMS_AND_CONDITIONS = 'terms';
export const SETTINGS_TAB_KEY_PUBLIC_SCHEDULE = 'schedule';
export const SETTINGS_TAB_KEY_CLIENTS = 'clients';
export const SETTINGS_TAB_KEY_WORKSHOPS = 'workshops';
export const SETTINGS_TAB_KEY_COURSES = 'courses';
export const SETTINGS_TAB_KEY_ORDERS = 'orders';
export const SETTINGS_TAB_KEY_NOTIFICATIONS = 'notifications';
export const SETTINGS_TAB_KEY_STATISTICS = 'statistics';
export const SETTINGS_TAB_KEY_SUBSCRIPTION_PAYMENT = 'subscription-payment';
export const SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION = 'activation';
export const SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW = 'subscription-overview';

export const APP_TAB_KEY_DASHBOARD = 'dashboard';
export const APP_TAB_KEY_ORGANISATIONS = 'organisations';
export const APP_TAB_KEY_OFFICE = 'office';

// user
export const USER_TAB_KEY_PERSONAL_INFO = 'personal-info';
export const USER_TAB_KEY_ORDERS = 'orders';

// statistics
export const STATISTICS_OVERVIEW = 'overview';
export const STATISTICS_REVENUES = 'revenues';

// payment settings
export const SUPPORTED_OFFICE_PAYMENT_METHODS = 'payment-methods';
export const ACCEPTED_LECTURE_PRICING_OPTIONS = 'pricing-options';

const { commonRoutesPaths } = reservedFrontendUrls;

export const commonRoutes = commonRoutesPaths;
export const studioRoutes = {
    tos: 'terms-and-conditions',
    pass: 'entry-pass',
    teacher: 'teacher',
    workshops: 'workshops',
    courses: 'courses',
};

// studio cannot have the same URL as any part of the application (e.g. /app, /login ....)
export const reservedUrls = [APPLICATION_SUBDOMAIN].concat(Object.values(commonRoutesPaths));
