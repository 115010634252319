import { InMemoryCache, makeVar } from '@apollo/client';

import { CALENDAR_VIEW_CALENDAR, appLocales } from '../../constants';
import LOCAL_STORAGE_KEYS from '../../enums/localStorageKeys';
import { initAppLocale } from '../../util/language';

export const newTariffInfoSeenVar = makeVar(!!localStorage.getItem(LOCAL_STORAGE_KEYS.NEW_TARIFF_INFO_SEEN) ?? false);
export const versionUpdateNeededVar = makeVar(
    !!localStorage.getItem(LOCAL_STORAGE_KEYS.VERSION_UPDATE_NEEDED) ?? false,
);
export const appLocaleVar = makeVar(initAppLocale(appLocales.EN));
export const isLoggedInVar = makeVar(!!localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN));
export const isNetworkErrorVar = makeVar(false);
export const isForbiddenErrorVar = makeVar(false);
export const isUnauthorizedErrorVar = makeVar(false);
export const locationToRedirectAfterLoginVar = makeVar(
    localStorage.getItem(LOCAL_STORAGE_KEYS.LOCATION_TO_REDIRECT_AFTER_LOGIN) ?? undefined,
);
export const calendarViewVar = makeVar(
    localStorage.getItem(LOCAL_STORAGE_KEYS.CALENDAR_VIEW) ?? CALENDAR_VIEW_CALENDAR,
);
export const selectedLicenseTariffActivationVar = makeVar({
    bookTariffId: null,
    paymentMethod: null,
    subjectName: null,
    street: null,
    city: null,
    postalCode: null,
    country: null,
    vatNumber: null,
    companyIdentificationNumber: null,
    isCompany: null,
    isVATPayer: null,
});
export const createReservationNotRegisteredUserVar = makeVar({});
export const createOrganisationRegistrationVar = makeVar({});

const loadUserIdVar = () => {
    const localStorageUserId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
    if (localStorageUserId) {
        return Number(localStorageUserId);
    }
    return null;
};

export const userIdVar = makeVar(loadUserIdVar());

/*
name
domain
image
organisationId
officeId
 */
const loadCurrentOfficeFromLocalStorage = () => {
    const currentOfficeSerialized = localStorage.getItem(LOCAL_STORAGE_KEYS.EMPLOYEE_CURRENT_HANDLED_OFFICE);
    if (currentOfficeSerialized) {
        try {
            return JSON.parse(currentOfficeSerialized);
        } catch (err) {
            console.error('Cannot deserialize current office from local storage');
        }
    }
    return null;
};

export const currentPublicViewStudioVar = makeVar(null);
export const employeeCurrentHandledOfficeVar = makeVar(loadCurrentOfficeFromLocalStorage());

// connect the Reactive Variable to a cache policy
export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                employeeCurrentHandledOffice: {
                    read: () => employeeCurrentHandledOfficeVar(),
                },
                appLocale: {
                    read: () => appLocaleVar(),
                },
                isLoggedIn: {
                    read: () => isLoggedInVar(),
                },
            },
        },
    },
});

// Clear current handled office when leaving office router
export const clearEmployeeCurrentHandledOrganisationOffice = () => {
    employeeCurrentHandledOfficeVar(null);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.EMPLOYEE_CURRENT_HANDLED_OFFICE);
};

export const setEmployeeCurrentHandledOrganisationOffice = employeeCurrentHandledOfficeObject => {
    employeeCurrentHandledOfficeVar(employeeCurrentHandledOfficeObject);
    localStorage.setItem(
        LOCAL_STORAGE_KEYS.EMPLOYEE_CURRENT_HANDLED_OFFICE,
        JSON.stringify(employeeCurrentHandledOfficeObject),
    );
};

export const setLocationToRedirectAfterLogin = location => {
    locationToRedirectAfterLoginVar(location);
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCATION_TO_REDIRECT_AFTER_LOGIN, location);
};

export const setUserIsLoggedIn = loggedIn => {
    isUnauthorizedErrorVar(false);
    isLoggedInVar(loggedIn);
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN, '1');
};

export const setUserId = userId => {
    userIdVar(userId);
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, userId);
};

export const setCalendarView = view => {
    calendarViewVar(view);
    localStorage.setItem(LOCAL_STORAGE_KEYS.CALENDAR_VIEW, view);
};

export const setAppLocale = lang => {
    appLocaleVar(lang);
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_LOCALE, lang);
};

export const clearLocalState = () => {
    currentPublicViewStudioVar(null);
    userIdVar(null);
    isLoggedInVar(false);
    employeeCurrentHandledOfficeVar(null);
    locationToRedirectAfterLoginVar(undefined);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_LOCALE);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.EMPLOYEE_CURRENT_HANDLED_OFFICE);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LOCATION_TO_REDIRECT_AFTER_LOGIN);
};
