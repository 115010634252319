import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import { Sidebar } from '../components/basics/SidebarDesktopMenu/Sidebar';
import { ZenamuLayout } from '../components/ZenamuLayout';
import { ZenamuTourProvider } from '../context/ZenamuTourProvider';
import { ContentHandler } from './ContentHandler';
import { FooterContainer } from './FooterContainer';
import { AuthorizedHeader } from './headers/AuthorizedHeader';

const { Footer, Content } = Layout;

export const AuthorizedLayoutComponent = ({
    children,
    isSidebarVisible = true,
}: {
    children: ReactNode;
    isSidebarVisible?: boolean;
}) => {
    return (
        <>
            <AuthorizedHeader isSidebarVisible={isSidebarVisible} />
            <ZenamuLayout>
                <Sidebar isSidebarVisible={isSidebarVisible} />
                <ZenamuLayout>
                    <ZenamuTourProvider>
                        <Content>
                            <ContentHandler>{children}</ContentHandler>
                        </Content>
                    </ZenamuTourProvider>
                </ZenamuLayout>
            </ZenamuLayout>
            <Footer>
                <FooterContainer />
            </Footer>
        </>
    );
};
