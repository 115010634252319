import React, { ReactNode, createContext, useState } from 'react';

// Define the context type
interface PublicOfficeContextType {
    officeBackground: string | null;
    handleSetOfficeBackground: (backgroundUrl: string | null) => void;
}

export const PublicOfficeContext = createContext<PublicOfficeContextType | undefined>(undefined);

interface PublicOfficeProviderProps {
    children: ReactNode;
}

export const PublicOfficeProvider = ({ children }: PublicOfficeProviderProps) => {
    const [officeBackground, setOfficeBackground] = useState<string | null>(null);

    const handleSetOfficeBackground = (backgroundUrl: string | null) => {
        setOfficeBackground(backgroundUrl);
    };

    return (
        <PublicOfficeContext.Provider
            value={{
                officeBackground,
                handleSetOfficeBackground,
            }}
        >
            {children}
        </PublicOfficeContext.Provider>
    );
};
