import { notification } from 'antd';

import i18n from './i18n';

export const showSuccessToast = (text: string) => {
    // message.success(text);
    notification.success({
        message: <>OK 🙌</>,
        description: text,
        placement: 'top',
        duration: 3,
    });
};

export const showErrorToast = (text: any, duration: number = 5) => {
    // message.error(text, duration);
    notification.error({
        message: <>{i18n.t('errorNs:errorPage.somethingWentWrongTitle')}</>,
        description: text,
        placement: 'top',
        duration,
    });
};
