import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';

import { config } from '../../config';
import { API_ENVIRONMENT_HOST, WS_ENVIRONMENT_HOST } from '../../constants';
import LOCAL_STORAGE_KEYS from '../../enums/localStorageKeys';
import { checkXVersionMatchCacheBusting } from '../../util/helpers';
import { cache } from './cache';
import { processAuthAndUnknownGraphQlAndNetworkApolloErrors } from './errors';
import { WebSocketLink } from './WebSocketLink';

const opts = {
    // credentials: 'same-origin', // if my server is same domain
    credentials: 'include', // it will store in the browser the cookies it gets back from the server. Works if my server is different domain
    // headers: {},
};

/**
 * This will probably be for mobile app
 * https://www.apollographql.com/docs/react/networking/authentication/
 * https://www.apollographql.com/docs/link/
 * @type {ApolloLink}
 */
/*
const requestAuthLink = new ApolloLink((operation, forward) => {
    const accessToken = getAccessToken();
    const oldHeaders = operation.getContext().headers;
    // modify the operation context with a new token
    operation.setContext(() => ({
        headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
        },
    }));

    // retry the request, returning the new observable
    return forward(operation);
});
 */

const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext();
        const xVersion = context.response.headers.get('x-version');
        checkXVersionMatchCacheBusting(xVersion);
        return response;
    });
});

// the link to the backend graphql API server
const httpLink = new HttpLink({
    uri: `${API_ENVIRONMENT_HOST}${config.GRAPHQL_PATH}`,
    headers: {
        zenamuapplocale: localStorage.getItem(LOCAL_STORAGE_KEYS.APP_LOCALE),
    },
    ...opts,
});

const wssLink = new WebSocketLink({
    url: `${WS_ENVIRONMENT_HOST}${config.WS_PATH}`,
    connectionParams: () => {
        // FIXME auth? https://www.apollographql.com/docs/apollo-server/data/subscriptions/#example-authentication-with-onconnect
        return {
            // authToken: 'TADY_BY_MEL_BYT_AUTHTOKEN',
        };
    },
    /*
    connectionParams: () => {
        const session = getSession();
        if (!session) {
            return {};
        }
        return {
            Authorization: `Bearer ${session.token}`,
        };
    },
     */
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const remoteLink = split(
    // split based on operation type
    ({ query }) => {
        const def = getMainDefinition(query);
        // Use WebSocket for only Subscriptions
        return def.kind === 'OperationDefinition' && def.operation === 'subscription';
    },
    wssLink,
    afterwareLink.concat(httpLink),
);

const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            processAuthAndUnknownGraphQlAndNetworkApolloErrors(graphQLErrors, networkError, client);
        }),
        remoteLink,
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
});

client.onClearStore(() => {});

export default client;
