import { CreditCardOutlined } from '@ant-design/icons';
import { officeUserRoles, paymentMethod as paymentMethodType } from '@fyooga/codebook';
import { parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InteractiveButton } from '../../../components/buttons';
import { APPLICATION_SUBDOMAIN } from '../../../constants';
import history from '../../../history';
import { useGetOrganisationOfficeLicenseData } from '../../../hooks/useGetOrganisationOfficeLicenseData';
import { useHasUserAccessByOfficeRole } from '../../../hooks/useHasUserAccessByOfficeRole';
import { useFormatters } from '../../../lib/formatters';
import {
    APP_TAB_KEY_OFFICE,
    SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW,
    SETTINGS_TAB_KEY_SUBSCRIPTION_PAYMENT,
} from '../../../router/constants';

export interface LicenceOrderHeaderInfoProps {
    organisationId: number;
    officeId: number;
    domain: string;
    onSetSubscriptionInfoText: (text: string, domain: string) => void;
}

export const LicenceOrderHeaderInfo = ({
    organisationId,
    officeId,
    domain,
    onSetSubscriptionInfoText,
}: LicenceOrderHeaderInfoProps) => {
    const { t } = useTranslation();
    const { DateFormatter } = useFormatters();
    const { data: organisationLicenseData, loading: loadingOrganisationLicenseData } =
        useGetOrganisationOfficeLicenseData(organisationId, officeId);
    const {
        hasAccess: canUserAccessSettingsForSuperadminRole,
        loading: loadingCanUserAccessSettingsForSuperadminRole,
    } = useHasUserAccessByOfficeRole(officeId, [officeUserRoles.SUPERADMIN]);

    if (loadingOrganisationLicenseData || loadingCanUserAccessSettingsForSuperadminRole) {
        return null;
    }

    if (organisationLicenseData?.currentOrganisationLicensePayment) {
        const { overdueDate, paymentMethod, id } = organisationLicenseData.currentOrganisationLicensePayment;

        onSetSubscriptionInfoText(
            t('subscriptionNs:waitingForPayment', {
                overdueDate: DateFormatter.format(parseISO(overdueDate), {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    ...(paymentMethod === paymentMethodType.CARD && {
                        hour: 'numeric',
                        minute: 'numeric',
                    }),
                }),
            }),
            domain,
        );

        return (
            <>
                {canUserAccessSettingsForSuperadminRole && (
                    <div className="expired-subscription-overview-mobile-button">
                        <InteractiveButton
                            icon={<CreditCardOutlined />}
                            type="link"
                            onClick={() =>
                                history.push(
                                    `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_PAYMENT}/${id}`,
                                )
                            }
                            value={t('subscriptionNs:waitingForPaymentGotoOverview')}
                        />
                    </div>
                )}
            </>
        );
    }

    return null;
};
