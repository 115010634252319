import './Styles.less';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import CharacterCount from '@tiptap/extension-character-count';
import Link from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect } from 'react';

import { BubbleMenuComponent } from './BubbleMenu';
import { MenuBar } from './MenuBar';

library.add(fas);

type TipTapEditorProps = {
    onChange?: (content: Content) => void;
    value: Content;
    editable: boolean;
    contentType?: 'html' | 'json';
    rerender?: boolean;
    onSetRerenderWysiwyg?: (rerender: boolean) => void;
    placeholder?: string;
    characterLimit?: number | null;
};

export const TipTapEditor = ({
    onChange,
    value,
    editable = true,
    contentType = 'json',
    rerender = false,
    placeholder,
    characterLimit = null,
    onSetRerenderWysiwyg,
}: TipTapEditorProps) => {
    const extensionsToAdd = [
        // See API methods: https://www.tiptap.dev/api/extensions/starter-kit/
        StarterKit.configure({
            // Configure an included extension
            heading: {
                levels: [1, 2, 3],
            },
        }),
        Underline,
        Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            placeholder: placeholder ?? '',
        }),
        Link.configure({
            autolink: false,
        }),
    ];

    if (characterLimit) {
        extensionsToAdd.push(
            // @ts-ignore
            CharacterCount.configure({
                limit: characterLimit,
            }),
        );
    }

    const editorInstance = useEditor(
        {
            extensions: extensionsToAdd,
            editorProps: {
                attributes: {
                    class: editable ? 'editor' : 'editor-readonly',
                },
            },
            content: value,
            editable,
            onUpdate: ({ editor }) => {
                if (editable && onChange) {
                    if (contentType === 'html') {
                        onChange(editor.getHTML());
                    } else {
                        onChange(editor.getJSON());
                    }
                }
            },
        },
        [rerender],
    );

    useEffect(() => {
        // to change state again
        if (rerender && onSetRerenderWysiwyg) {
            onSetRerenderWysiwyg(false);
        }
    }, [rerender]);

    return (
        <>
            {editable && (
                <>
                    <BubbleMenuComponent editor={editorInstance} />
                    <MenuBar editor={editorInstance} />
                </>
            )}
            <EditorContent editor={editorInstance} />
            {editorInstance && characterLimit && (
                <div className="character-count">
                    {editorInstance.storage.characterCount.characters()}/{characterLimit}
                </div>
            )}
        </>
    );
};
