import { FormatDateOptions } from '@formatjs/intl';

import { makeFormatter } from '../makeFormatter';
import { FormatterConfig } from '../types';

export type DateFormatterDataContext<T> = {
    [K in keyof T]: T[K];
};

export interface DateDataContext extends FormatDateOptions {
    withDayName?: boolean;
}

export const prepareDateFormatter = (config: FormatterConfig) =>
    makeFormatter<Date, string, DateFormatterDataContext<DateDataContext>>((date, dataContext) => {
        const { intl } = config;
        const { withDayName } = dataContext;

        return intl.formatDate(date, {
            ...dataContext,
            ...(withDayName && {
                weekday: 'long',
            }),
        });
    });
