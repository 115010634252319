import { formFeedback } from '@fyooga/errors';
import axiosLib from 'axios';

import { API_ENVIRONMENT_HOST } from '../../constants';
import { ApiError } from '../../lib/error/ApiError';
import { showErrorToast } from '../../lib/toasts';
import { checkXVersionMatchCacheBusting } from '../../util/helpers';

const commonHeaders = {
    'Content-Type': 'application/json',
    'Referrer-Policy': 'origin',
};

export const axiosClient = axiosLib.create({
    baseURL: `${API_ENVIRONMENT_HOST}`,
    timeout: 10000,
    headers: commonHeaders,
    withCredentials: true,
});

// Add a response interceptor
axiosClient.interceptors.response.use(response => {
    checkXVersionMatchCacheBusting(response.headers['x-version']);
    return response; // continue with response
});

export const processApiError = error => {
    if (error.response) {
        throw new ApiError('Request made and server responded with error.', error.response.data.message);
    } else if (error.request) {
        throw new ApiError(
            'The request was made but no response was received.',
            formFeedback.API_IS_UNAVAILABLE_500.code,
        );
    } else {
        throw new ApiError(
            process.env.NODE_ENV !== 'production' && error?.message
                ? error.message
                : 'Something happened in setting up the request that triggered an Error.',
            formFeedback.API_IS_UNAVAILABLE_500.code,
        );
    }
};

export const processApiResponse = response => {
    const { status, data } = response;

    if (status === 200 || status === 201 || status === 204) {
        return data;
    }
    throw new Error(`Request has not been successful. Status: ${status}.`);
};

// eslint-disable-next-line consistent-return
export const logInUser = async (email, password, rememberMe = false, recaptchaToken = '', recaptchaActionName = '') => {
    try {
        const loginUserResponse = await axiosClient.post('/login', {
            email,
            password,
            rememberMe,
            recaptchaToken,
            recaptchaActionName,
        });
        return processApiResponse(loginUserResponse);
    } catch (err) {
        processApiError(err);
    }
};

export const onboardConnectedAccount = async (officeId, refreshUrl, returnUrl) => {
    return await axiosClient.post('/stripe/onboard-connected-account', {
        officeId,
        refreshUrl,
        returnUrl,
    });
};

export const getPaymentIntentForClientOrder = async orderId => {
    return await axiosClient.get(`/stripe/payment-intent-account-order/${orderId}`);
};

export const getInvoiceStatus = async invoiceId => {
    return await axiosClient.get(`/stripe/invoice-status/${invoiceId}`);
};

export const getPaymentIntentForZenamuSubscription = async licenseOrderGraphQlId => {
    return await axiosClient.get(`/stripe/payment-intent-license-order/${licenseOrderGraphQlId}`);
};

export const getUpcomingZenamuLicenseInvoice = async (
    officeId,
    organisationId,
    licensePaymentAddressId,
    newPriceId,
    type,
) => {
    return await axiosClient.get(
        `/stripe/retrieve-upcoming-invoice/organisations/${organisationId}/offices/${officeId}/license-address/${licensePaymentAddressId}/new-price/${newPriceId}/types/${type}`,
    );
};

export const getStripeSubscriptionSummary = async (officeId, licensePaymentAddressId) => {
    return await axiosClient.get(
        `/stripe/retrieve-subscription-summary/offices/${officeId}/license-address/${licensePaymentAddressId}`,
    );
};

export const updatePaymentMethodForZenamuSubscription = async ({
    subscriptionId,
    paymentIntentId,
    paymentMethodId,
}) => {
    return await axiosClient.post(`/stripe/update-payment-method`, {
        subscriptionId,
        paymentIntentId,
        paymentMethodId,
    });
};

export const createSetupIntentForZenamuSubscription = async (officeId, licensePaymentAddressId) => {
    return await axiosClient.post(`/stripe/create-setup-intent`, {
        officeId,
        licensePaymentAddressId,
    });
};

export const changeCustomerPaymentMethodForZenamuSubscription = async ({
    officeId,
    licensePaymentAddressId,
    stripePaymentMethodId,
    setupIntentId,
}) => {
    return await axiosClient.post(`/stripe/change-customer-payment-method`, {
        officeId,
        licensePaymentAddressId,
        stripePaymentMethodId,
        setupIntentId,
    });
};

export const getConnectedAccount = async officeId => {
    return await axiosClient.get(`/stripe/get-connected-account/${officeId}`);
};

// eslint-disable-next-line consistent-return
export const logoutUser = async () => {
    try {
        const logoutUserResponse = await axiosClient.post('/logout');
        return processApiResponse(logoutUserResponse);
    } catch (err) {
        showErrorToast(err.message);
    }
};

export const getAsyncValidationDuplicity = async (type, field, value) => {
    return await axiosClient.get('/asyncValidation', {
        params: {
            type,
            field,
            value,
        },
    });
};

export const downloadReceipt = async (officeId, organisationId, clientOrderGraphQlId, type, regenerate) => {
    return await axiosClient.get(
        `/downloadReceipt/organisations/${organisationId}/offices/${officeId}/orders/${clientOrderGraphQlId}`,
        {
            responseType: 'arraybuffer', // Important: Get the response as binary data
            timeout: 30000, // Set the timeout to 30 seconds (for example)
            params: {
                regenerate,
                type,
            },
        },
    );
};

export const getAsyncValidationExistingUser = async (type, field, email, officeId) => {
    return await axiosClient.get('/asyncValidation', {
        params: {
            type,
            field,
            value: email,
            officeId,
        },
    });
};

export const getThePayLicensePaymentOrderStatus = async (paymentUid, projectId) => {
    return await axiosClient.get(`/thepay/project/${projectId}/license-payment-status/${paymentUid}`);
};

export const getThePayInfo = async () => {
    return await axiosClient.get(`/thepay/info`);
};

export const getSmartEmailingCheckCredentials = async officeId => {
    return await axiosClient.get(`/smartemailing/${officeId}/checkCredentials`);
};

export const getEcomailCheckCredentials = async officeId => {
    return await axiosClient.get(`/ecomail/${officeId}/checkCredentials`);
};

export const getMailchimpCheckCredentials = async officeId => {
    return await axiosClient.get(`/mailchimp/${officeId}/checkCredentials`);
};

export const authMailchimp = async officeId => {
    return await axiosClient.get(`/mailchimp/auth`, {
        params: {
            officeId,
        },
    });
};

export const confirmMailchimpCallback = async (code, officeId) => {
    return await axiosClient.get(`/mailchimp/auth/callback`, {
        params: {
            code,
            officeId,
        },
    });
};

export const getMailchimpAudienceList = async officeId => {
    return await axiosClient.get(`/mailchimp/offices/${officeId}/audiences`);
};

export const getConversionRatesForCurrency = async currencyCode => {
    return await axiosClient.get(`/system/conversionRates/${currencyCode}`);
};

export const exportClientsCsv = async (officeId, queryParams) => {
    return await axiosClient.get(`/exportClients/offices/${officeId}`, {
        params: {
            ...queryParams,
        },
    });
};

export const exportOfficeReceiptsCsv = async (officeId, userAppLocale) => {
    return await axiosClient.get(`/exportReceipts/offices/${officeId}`, {
        params: {
            lang: userAppLocale,
        },
    });
};
