import getSlug from 'speakingurl';

export function slugifyRegistrationName(name: string): string {
    const slug = getSlug(name);
    return slug.replace(/_/g, '');
}

export const getAllOfficesMembershipArray = (organisationsEmployeeList: any[]) => {
    const allOfficesMembershipArrays = organisationsEmployeeList.map(
        organisationEmployeeMembership => organisationEmployeeMembership.organisationOffices,
    );
    const allOfficesMembershipArray = allOfficesMembershipArrays.flat();
    return allOfficesMembershipArray;
};
