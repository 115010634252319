import { useReactiveVar } from '@apollo/client';
import { permissions } from '@fyooga/codebook';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import { useHasUserAccessByOrganisationRole } from '../../hooks/useHasUserAccessByOrganisationRole';
import { employeeCurrentHandledOfficeVar, userIdVar } from '../../lib/apollo/cache';
import { APP_TAB_KEY_OFFICE, SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW } from '../../router/constants';
import { ExpiredSubscriptionOverviewContent } from './ExpiredSubscriptionOverviewContent';
import { UserProfileDropdown } from './UserProfileDropdown';

export const ExpiredSubscriptionOverview = () => {
    const userId = useReactiveVar(userIdVar);
    const [subscriptionInfoText, setSubscriptionInfoText] = useState<string | null>(null);
    const [subscriptionInfoTextDomain, setSubscriptionInfoTextDomain] = useState<string | null>(null);
    const employeeCurrentHandledOfficeData = useReactiveVar(employeeCurrentHandledOfficeVar);

    const canUserWriteOrgTariffInfo = useHasUserAccessByOrganisationRole(
        employeeCurrentHandledOfficeData?.officeId ?? null,
        employeeCurrentHandledOfficeData?.organisationId ?? null,
        permissions.WRITE_ORGANISATION_TARIFF_INFO.bitPermissions,
    );

    const onSetSubscriptionInfoText = (text: string | null, domain: string | null) => {
        setSubscriptionInfoText(text);
        setSubscriptionInfoTextDomain(domain);
    };

    return (
        <div className="app-headline--container">
            {subscriptionInfoText && subscriptionInfoTextDomain && !isMobile && (
                <div className="app-headline-subscription-overview">
                    <Link
                        to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${subscriptionInfoTextDomain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW}`}
                    >
                        <div className="expired-subscription-overview-mobile-description">{subscriptionInfoText}</div>
                    </Link>
                </div>
            )}
            <div className="app-settings-menu-headline">
                {!isMobile &&
                employeeCurrentHandledOfficeData?.officeId &&
                employeeCurrentHandledOfficeData?.organisationId ? (
                    <div className="expired-subscription-overview">
                        <ExpiredSubscriptionOverviewContent
                            onSetSubscriptionInfoText={onSetSubscriptionInfoText}
                            canUserWriteOrgTariffInfo={canUserWriteOrgTariffInfo}
                            officeId={employeeCurrentHandledOfficeData.officeId}
                            organisationId={employeeCurrentHandledOfficeData.organisationId}
                        />
                    </div>
                ) : null}
                <UserProfileDropdown />
                <LanguageSwitcher userId={userId} />
            </div>
        </div>
    );
};
