import download from './images/icons/download.png';

export type IconImageName = keyof typeof ImageMap;

export const ImageMap = {
    download: download,
};

export const allImageIcons = () => {
    const iconsArray = [];
    for (const property in ImageMap) {
        iconsArray.push(property);
    }
    return iconsArray;
};
