/**
 * @readonly
 * @enum {string}
 */
export default {
    LOCATION_TO_REDIRECT_AFTER_LOGIN: 'locationToAfterLogin',
    IS_LOGGED_IN: 'isLoggedIn',
    USER_ID: 'userId',
    APP_LOCALE: 'appLocale',
    EMPLOYEE_CURRENT_HANDLED_OFFICE: 'employeeCurrentHandledOffice',
    CALENDAR_VIEW: 'calendarView',
    VERSION_UPDATE_NEEDED: 'versionUpdateNeeded',
    MAILCHIMP_OFFICE_ID: 'mailchimpOfficeId',
    NEW_TARIFF_INFO_SEEN: 'newTariffInfoSeen',
};
