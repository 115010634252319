import { loadStripe } from '@stripe/stripe-js';
import React, { createContext } from 'react';

import { config } from '../config';

export const StripeContext = createContext();

// call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

export const StripeProvider = ({ children }) => {
    return (
        <StripeContext.Provider
            value={{
                stripePromise,
            }}
        >
            {children}
        </StripeContext.Provider>
    );
};
