import { useQuery } from '@apollo/client';
import { permissions } from '@fyooga/codebook';
import { fyoogaQueries, fyoogaSubscriptions } from '@fyooga/graphql-requests';
import { useEffect } from 'react';

import { LicensePaymentT } from '../types/subscription';
import { useHasUserAccessByOfficePermissions } from './useHasUserAccessByOfficePermissions';

export const useSubscribeGetOrganisationLicensePaymentsById = (organisationId: number, officeId: number) => {
    const { loading: loadingCanUserWriteOrgTariffInfo, hasAccess: canUserWriteOrgTariffInfo } =
        useHasUserAccessByOfficePermissions(officeId, permissions.WRITE_ORGANISATION_TARIFF_INFO.bitPermissions);

    const {
        subscribeToMore: subscribeToMoreOrganisationLicensePayments,
        loading: loadingLicensePayments,
        data: licensePaymentsData,
        refetch,
    } = useQuery(fyoogaQueries.getOrganisationLicensePaymentsById, {
        fetchPolicy: 'network-only',
        variables: {
            officeId,
            organisationId,
        },
        skip: !(!loadingCanUserWriteOrgTariffInfo && canUserWriteOrgTariffInfo),
    });

    useEffect(() => {
        const unsubscribeOrganisationLicensePayments = subscribeToMoreOrganisationLicensePayments({
            document: fyoogaSubscriptions.organisationLastOrganisationLicensePaymentUpdated,
            variables: { organisationId },
            updateQuery: (currentlyCachedResultData, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return currentlyCachedResultData;
                }
                const updatedOrganisationSubscriptionData =
                    subscriptionData.data.organisationLastOrganisationLicensePaymentUpdated;
                const { lastOrganisationLicensePayment: updatedLastOrganisationLicensePayment } =
                    updatedOrganisationSubscriptionData;
                if (!updatedLastOrganisationLicensePayment) {
                    return currentlyCachedResultData;
                }

                const organisationLicensePaymentsWithUpdatedPayment =
                    currentlyCachedResultData?.organisationLicensePaymentsById
                        ? currentlyCachedResultData.organisationLicensePaymentsById.map((payment: LicensePaymentT) => {
                              if (payment.licensePaymentId === updatedLastOrganisationLicensePayment.licensePaymentId) {
                                  return {
                                      ...payment,
                                      ...updatedLastOrganisationLicensePayment,
                                  };
                              }
                              return payment;
                          })
                        : [updatedLastOrganisationLicensePayment];
                return {
                    organisationLicensePaymentsById: organisationLicensePaymentsWithUpdatedPayment,
                };
            },
        });
        return () => {
            unsubscribeOrganisationLicensePayments();
        };
    }, []);

    return {
        loading: loadingLicensePayments,
        data: licensePaymentsData,
        refetch,
    };
};
