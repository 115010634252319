// @ts-nocheck

export interface ConfigT {
    APP_NAME: string;
    FRONTEND_HOST: string;
    API_HOST: string;
    WS_HOST: string;
    API_PORT: string;
    WS_PATH: string;
    GRAPHQL_PATH: string;
    RELEASE_VERSION: string;
    GOOGLE_ANALYTICS_UA: string;
    SYSTEM_SUPERADMIN_IDS: string;
    AWS_CLOUDFRONT_DOMAIN: string;
    RECAPTCHA_V3_SITE_KEY: string;
    IP_INFO_KEY: string;
    STRIPE_PUBLIC_KEY: string;
    SENTRY_URL: string;
}

export const config: ConfigT = {
    APP_NAME: process.env.REACT_APP_APP_NAME,
    FRONTEND_HOST: process.env.REACT_APP_FRONTEND_HOST,
    API_HOST: process.env.REACT_APP_API_HOST,
    WS_HOST: process.env.REACT_APP_WS_HOST,
    API_PORT: process.env.REACT_APP_API_PORT,
    WS_PATH: process.env.REACT_APP_WS_PATH,
    GRAPHQL_PATH: process.env.REACT_APP_GRAPHQL_PATH,
    RELEASE_VERSION: process.env.REACT_APP_RELEASE_VERSION,
    GOOGLE_ANALYTICS_UA: process.env.REACT_APP_GOOGLE_ANALYTICS_UA,
    SYSTEM_SUPERADMIN_IDS: process.env.REACT_APP_SYSTEM_SUPERADMIN_IDS,
    AWS_CLOUDFRONT_DOMAIN: process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN,
    RECAPTCHA_V3_SITE_KEY: process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY,
    IP_INFO_KEY: process.env.REACT_APP_IP_INFO_KEY,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    SENTRY_URL: process.env.REACT_APP_SENTRY_URL,
};
