import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Page } from '../../components/ui/Page';
import { config } from '../../config';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import { useLoggedIn } from '../../hooks/apollo/useLoggedIn';

export interface ErrorNotFoundProps {
    text?: string;
}

export const ErrorNotFound = ({ text }: ErrorNotFoundProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const isLoggedIn = useLoggedIn();

    /*
    https://www.google.com/search?q=funny+design+error+page&rlz=1C5CHFA_enCZ915CZ915&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjG4O2xlM7yAhVL_rsIHWmDCjkQ_AUoAXoECAEQAw&biw=2560&bih=1304#imgrc=xGw98vfWv_CwuM
     */
    return (
        <Page headerTitle={t('errorNs:errorPage.notFoundTitle', location.pathname)} align="center">
            {text && <p>{text}</p>}
            {isLoggedIn ? (
                <p>
                    <a
                        href={`/${APPLICATION_SUBDOMAIN}/`}
                        onClick={() => (window.location.href = `/${APPLICATION_SUBDOMAIN}/`)}
                    >
                        {t('errorNs:errorPage.backToDashboard')}
                    </a>
                </p>
            ) : (
                <p>
                    <a href={config.FRONTEND_HOST}>{t('errorNs:errorPage.goToHome')}</a>
                </p>
            )}
        </Page>
    );
};
