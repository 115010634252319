import hexToRgba from 'hex-to-rgba';

// const primaryColorHex = '#BB778B'; // ruzova
// const secondaryColorHex = '#888BBB'; // fialova

const primaryColorHex = '#663399'; // zluta
const secondaryColorHex = '#888BBB'; // fialova

export const originalPrimaryColor = hexToRgba('#E99900', '1.0');
export const primaryColor = hexToRgba(primaryColorHex, '1.0');
export const primaryColorLight = hexToRgba(primaryColorHex, '0.2');
// export const primaryColorLight = '#faebed';
export const secondaryColor = hexToRgba(secondaryColorHex, '1.0');
export const secondaryColorLight = hexToRgba(secondaryColorHex, '0.1');

export const theme = {
    background: '#f5f6f9',
    text: '#222222',
    buttonText: '#222222',
    gray900: '#1F1F1F',
    gray800: '#333333',
    gray700: '#545454',
    gray600: '#757575',
    gray500: '#AFAFAF',
    gray400: '#CBCBCB',
    gray300: '#E2E2E2',
    gray200: '#EEEEEE',
    gray100: '#F6F6F6',

    primary: 'rgb(5,73,128)',
    primaryLight: 'rgb(134,197,255)',
    primaryHover: 'rgb(64,255,175)',
    secondary: '#008d52',
    secondaryLight: '#bbf6db',
    primaryColor,
    secondaryColor,
    originalPrimaryColor,

    headerBg: '#AFAFAF',

    sidebarBg: 'rgba(187,0,66,0.59)',
    sidebarItemIcon: '#ffffff',
    sidebarItemIconSelected: 'rgba(187,0,66,0.59)',
};

/**
 * For styled components
 */

export const defaultLineColor = '#e1e3e8';
export const bgColorLayout = '#f0f0f0';
export const borderRadiusBase = '8px';
export const borderRadiusBaseNumber = 8;
export const componentBackground = '#fff';
export const colorNotSoDarkText = '#70757b';
export const colorVeryDarkText = '#202224';
export const colorDarkText = '#3c4043';

export const linearGradient = `linear-gradient(to bottom, ${primaryColor}, ${primaryColorLight})`;

export const sidebarItemIcon = '#ffffff';

export const radioPricingStyle = (isSelected: boolean) => ({
    // display: 'inline-flex', // this solves the problem with width of radio select and set full width of parent
    border: `2px solid ${isSelected ? primaryColor : defaultLineColor}`,
    borderRadius: '5px',
    padding: '16px',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px',
});
