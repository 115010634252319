import { Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { settingsIcon } from '../../assets/images';
import LOCAL_STORAGE_KEYS from '../../enums/localStorageKeys';
import { versionUpdateNeededVar } from '../../lib/apollo/cache';
import { InteractiveButton } from '../buttons';
import { ModalWrapper } from './ModalWrapper';

interface CacheBustingRefreshModalProps {
    visible: boolean;
    onCloseModal: () => void;
}

export const CacheBustingRefreshModal = ({ visible, onCloseModal }: CacheBustingRefreshModalProps) => {
    const { t } = useTranslation();
    return (
        <ModalWrapper
            title={t('cacheBusting.title')}
            closable={false}
            maskClosable={false}
            destroyOnClose
            visible={visible}
            onClose={onCloseModal}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Image preview={false} src={settingsIcon} width={100} />
                <p>{t('cacheBusting.subtitle1')}</p>
                <p>{t('cacheBusting.subtitle2')}</p>
            </div>
            <InteractiveButton
                block
                value={t('cacheBusting.button')}
                onClick={() => {
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.VERSION_UPDATE_NEEDED); // remove the storage object
                    versionUpdateNeededVar(false);
                    window.location.reload(); // refresh the browser
                }}
            />
        </ModalWrapper>
    );
};
