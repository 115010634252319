import { useQuery } from '@apollo/client';
import { permissions } from '@fyooga/codebook';
import { fyoogaQueries } from '@fyooga/graphql-requests';

import { useHasUserAccessByOfficePermissions } from './useHasUserAccessByOfficePermissions';

export const useSubscribeGetOrganisationById = (organisationId: number, skip: boolean) => {
    const {
        loading: loadingOrganisation,
        data: officeOrganisationData,
        refetch,
    } = useQuery(fyoogaQueries.getOrganisationById, {
        fetchPolicy: 'network-only',
        variables: {
            organisationId,
        },
        skip,
    });

    return {
        loading: loadingOrganisation,
        data: officeOrganisationData,
        refetch,
    };
};
