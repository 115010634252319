import React, { useEffect } from 'react';

import { PublicAppRouter } from '../router/PublicAppRouter';
import { PublicLayoutComponent } from './PublicLayoutComponent';

export const PublicLayout = () => {
    // this is hack to avoid this reported bug: https://github.com/ant-design/ant-design/issues/21539
    useEffect(() => {
        document.body.style.removeProperty('overflow');
    }, []);

    return (
        <PublicLayoutComponent>
            <PublicAppRouter />
        </PublicLayoutComponent>
    );
};
