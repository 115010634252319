import { UserOutlined } from '@ant-design/icons';
import { Avatar, Popover } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import initials from 'initials';
import React, { CSSProperties, FC, ReactElement, ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { colorDarkText, linearGradient } from '../../../theme';
import { TipTapEditor } from '../../ui/editor/TipTapEditor';
import { ModalWrapper } from '../../ui/ModalWrapper';

export interface LecturerIconProps {
    name?: string;
    image?: string | null;
    withName?: boolean;
    size?: AvatarSize;
    withPopover?: boolean;
    popoverDescription?: string | null;
    withOpenModal?: boolean;
}

const LecturerWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const LecturerIconStyle = styled.div``;

const LecturerNameStyle = styled.div`
    font-weight: 500;
    letter-spacing: -0.4px;
    color: ${colorDarkText};
`;

const LecturerIcon: FC<LecturerIconProps> = ({
    name,
    image,
    withName = true,
    size = 20,
    popoverDescription,
    withOpenModal = true,
}) => {
    const [lecturerModalVisible, setLecturerModalVisible] = useState(false);

    const lecturerInitials = name ? initials(name) : null;
    let avatarProps: { src?: ReactNode; icon?: ReactElement } = {};

    const avatarStyle: CSSProperties = {
        ...(withName && { marginRight: '6px' }),
    };

    if (image) {
        avatarProps = {
            ...avatarProps,
            src: <img src={image} width="100%" height="100%" className="lazyLoadedCoverImage" />,
        };
    } else if (!name) {
        avatarProps = {
            ...avatarProps,
            icon: <UserOutlined />,
        };
    }

    if (!avatarProps.src) {
        avatarStyle.backgroundImage = linearGradient;
    }

    return (
        <>
            <LecturerWrapper onClick={() => withOpenModal && setLecturerModalVisible(true)}>
                <LecturerIconStyle>
                    <Avatar {...avatarProps} style={avatarStyle} size={size} alt={name}>
                        {lecturerInitials}
                    </Avatar>
                </LecturerIconStyle>
                {withName && name && <LecturerNameStyle>{name}</LecturerNameStyle>}
            </LecturerWrapper>
            {
                <ModalWrapper
                    title={name}
                    visible={lecturerModalVisible}
                    onClose={() => setLecturerModalVisible(false)}
                    closable
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1.25rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar {...avatarProps} style={avatarStyle} size={!isMobile ? 150 : 100} alt={name}>
                            {lecturerInitials}
                        </Avatar>
                        {popoverDescription && <TipTapEditor value={popoverDescription} editable={false} />}
                    </div>
                </ModalWrapper>
            }
        </>
    );
};

export default LecturerIcon;
