import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { processTipTapLink } from '../../../util/tipTapEditor';

export const MenuBar = ({ editor }) => {
    const { t } = useTranslation();

    const setLink = useCallback(() => {
        processTipTapLink(editor);
    }, [editor]);

    if (!editor) {
        return null;
    }

    return (
        <>
            <button
                type="button"
                title={t('wysiwygEditor.bold')}
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={classNames('menu-button', editor.isActive('bold') && 'is-active')}
            >
                <FontAwesomeIcon icon="bold" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.italic')}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={classNames('menu-button', editor.isActive('italic') && 'is-active')}
            >
                <FontAwesomeIcon icon="italic" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.underline')}
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={classNames('menu-button', editor.isActive('underline') && 'is-active')}
            >
                <FontAwesomeIcon icon="underline" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.strikethrough')}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={classNames('menu-button', editor.isActive('strike') && 'is-active')}
            >
                <FontAwesomeIcon icon="strikethrough" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.H1')}
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={classNames('menu-button', editor.isActive('heading', { level: 1 }) && 'is-active')}
            >
                H1
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.H2')}
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={classNames('menu-button', editor.isActive('heading', { level: 2 }) && 'is-active')}
            >
                H2
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.H3')}
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={classNames('menu-button', editor.isActive('heading', { level: 3 }) && 'is-active')}
            >
                H3
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.link')}
                onClick={setLink}
                className={classNames('menu-button', editor.isActive('link') && 'is-active')}
            >
                <FontAwesomeIcon icon="link" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.unorderedList')}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={classNames('menu-button', editor.isActive('bulletList') && 'is-active')}
            >
                <FontAwesomeIcon icon="list-ul" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.orderedList')}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={classNames('menu-button', editor.isActive('orderedList') && 'is-active')}
            >
                <FontAwesomeIcon icon="list-ol" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.blockquote')}
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={classNames('menu-button', editor.isActive('blockquote') && 'is-active')}
            >
                <FontAwesomeIcon icon="quote-left" />
            </button>
            <button
                type="button"
                title={t('wysiwygEditor.horizontalRule')}
                className="menu-button"
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
            >
                —
            </button>
        </>
    );
};
