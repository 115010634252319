import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { APPLICATION_SUBDOMAIN } from '../constants';
import { clearEmployeeCurrentHandledOrganisationOffice, isNetworkErrorVar } from '../lib/apollo/cache';
import { AuthorizedAppRouter } from '../router/AuthorizedAppRouter';
import { APP_TAB_KEY_OFFICE } from '../router/constants';
import { AuthorizedLayoutComponent } from './AuthorizedLayoutComponent';

export const AuthorizedLayout = () => {
    const isNetworkError = useReactiveVar(isNetworkErrorVar);

    const { pathname } = useLocation();

    // const [collapsed, setCollapsed] = useState(false);
    // this is hack to avoid this reported bug: https://github.com/ant-design/ant-design/issues/21539
    useEffect(() => {
        document.body.style.removeProperty('overflow');
    }, []);

    const isAppOfficeSection = useMemo(() => {
        if (pathname && pathname.startsWith(`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}`)) {
            return true;
        }

        clearEmployeeCurrentHandledOrganisationOffice();
        return false;
    }, [pathname]);

    /*
    const onCollapse = isCollapsed => {
        setCollapsed(isCollapsed);
    };
    */

    const isSidebarVisible = !isNetworkError && isAppOfficeSection;

    // collapsible collapsed={collapsed} onCollapse={onCollapse}
    return (
        <AuthorizedLayoutComponent isSidebarVisible={!!isSidebarVisible}>
            <AuthorizedAppRouter />
        </AuthorizedLayoutComponent>
    );
};
