import './polyfills';
import './lib/i18n';

import { ApolloProvider as ApolloHooksProvider } from '@apollo/client/react/context/ApolloProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Router } from 'react-router-dom';

import { ScrollToTop } from './components/ScrollToTop';
import { config } from './config';
import { AppProviders } from './context/AppProviders';
import { AuthProvider } from './context/AuthProvider';
import { PublicOfficeProvider } from './context/PublicOfficeProvider';
import { StripeProvider } from './context/StripeProvider';
import history from './history';
import apolloClient from './lib/apollo/client';
import * as serviceWorker from './serviceWorker';

if (config.FRONTEND_HOST.includes('app.zenamu.com')) {
    const tagManagerArgs = {
        gtmId: 'GTM-MRSC4WC4',
    };

    TagManager.initialize(tagManagerArgs);
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: config.SENTRY_URL,
        integrations: [new BrowserTracing()],
        environment: process.env.NODE_ENV,
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 0.3,
    });
}

export const WithScrollToTopWrapper = ({ condition, children }: { condition: boolean; children: ReactNode }) => {
    return condition ? <ScrollToTop>{children}</ScrollToTop> : <>{children}</>;
};

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <ApolloHooksProvider client={apolloClient}>
        <Router history={history}>
            <WithScrollToTopWrapper condition>
                <PublicOfficeProvider>
                    <StripeProvider>
                        <AuthProvider>
                            <AppProviders />
                        </AuthProvider>
                    </StripeProvider>
                </PublicOfficeProvider>
            </WithScrollToTopWrapper>
        </Router>
    </ApolloHooksProvider>,
);

// serviceWorker.unregister();
serviceWorker.register();
