import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CookiesContext } from '../context/CookiesProvider';

export const CookiePopup = () => {
    const { t } = useTranslation('cookieBarNs');
    const { cookieConsent } = useContext<any>(CookiesContext);

    return (
        <>
            {cookieConsent ? (
                <div onClick={() => cookieConsent.showSettings()}>
                    <Link to="#">{t('cookie_provider.title')}</Link>
                </div>
            ) : null}
        </>
    );
};
