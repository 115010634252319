import { FormatDateOptions } from '@formatjs/intl';
import React, { createContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DateDataContext, DateFormatterDataContext, prepareDateFormatter } from './formatters/prepareDateFormatter';
import {
    DateTimeRangeFormatterDataContext,
    prepareDateTimeRangeFormatter,
} from './formatters/prepareDateTimeRangeFormatter';
import { EntryFormatterDataContext, prepareEntryFormatter } from './formatters/prepareEntryFormatter';
import {
    FiatAmountFormatterDataContext,
    FiatDataContext,
    prepareFiatAmountFormatter,
} from './formatters/prepareFiatAmountFormatter';
import {
    SimpleDatetimeDataContext,
    SimpleDatetimeFormatterDataContext,
    prepareSimpleDateTimeFormatter,
} from './formatters/prepareSimpleDateTimeFormatter';
import { TimeDataContext, TimeFormatterDataContext, prepareTimeFormatter } from './formatters/prepareTimeFormatter';
import { Formatter } from './makeFormatter';
import { FormatterConfig, FormatterProviderConfig } from './types';

type FormatterProviderProps = {
    children: React.ReactNode;
    config: FormatterProviderConfig;
};

export type Formatters = {
    TimeFormatter: Formatter<Date, string, TimeFormatterDataContext<TimeDataContext>>;
    DateFormatter: Formatter<Date, string, DateFormatterDataContext<DateDataContext>>;
    DateTimeRangeFormatter: Formatter<
        { from: Date; to: Date },
        string,
        DateTimeRangeFormatterDataContext<FormatDateOptions>
    >;
    FiatAmountFormatter: Formatter<string | number, string, FiatAmountFormatterDataContext<FiatDataContext>>;
    EntryFormatter: Formatter<string | number, string, EntryFormatterDataContext>;
    SimpleDateTimeFormatter: Formatter<Date, string, SimpleDatetimeFormatterDataContext<SimpleDatetimeDataContext>>;
};

export const FormatterProviderContext = createContext<Formatters>({} as Formatters);

export const getFormatters = (config: FormatterConfig): Formatters => {
    const TimeFormatter = prepareTimeFormatter(config);
    const DateFormatter = prepareDateFormatter(config);
    const DateTimeRangeFormatter = prepareDateTimeRangeFormatter(config);
    const FiatAmountFormatter = prepareFiatAmountFormatter(config);
    const EntryFormatter = prepareEntryFormatter(config);
    const SimpleDateTimeFormatter = prepareSimpleDateTimeFormatter(config);

    return {
        EntryFormatter,
        FiatAmountFormatter,
        DateFormatter,
        TimeFormatter,
        DateTimeRangeFormatter,
        SimpleDateTimeFormatter,
    };
};

export const FormatterProvider = ({ config, children }: FormatterProviderProps) => {
    const intl = useIntl();

    const contextValue = useMemo(() => {
        const extendedConfig = {
            ...config,
            intl,
        };
        const formatters = getFormatters(extendedConfig);

        return formatters;
    }, [config, intl]);

    return <FormatterProviderContext.Provider value={contextValue}>{children}</FormatterProviderContext.Provider>;
};
