import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { ReactNode } from 'react';

export interface ZenamuAntButtonT extends Omit<ButtonProps, 'value'> {
    value?: string | ReactNode;
}

const CreateAntButton = ({
    value,
    type = 'primary',
    danger = false,
    size = 'large',
    className,
    loading = false,
    block = false,
    ...otherProps
}: ZenamuAntButtonT) => {
    return (
        <Button
            size={size}
            htmlType="button"
            type={type}
            danger={danger}
            // className={classNames(`app-button__${type}`, className && className)}
            className={className && className}
            loading={loading}
            block={block}
            {...otherProps}
        >
            {value}
        </Button>
    );
};

export const InteractiveButton = CreateAntButton;
