import { Dropdown } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { APPLICATION_SUBDOMAIN } from '../../constants';
import history from '../../history';
import { useEmployeeCurrentHandledOfficeData } from '../../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { APP_TAB_KEY_OFFICE } from '../../router/constants';
import { getAllOfficesMembershipArray } from '../../util/organisation';

export interface OfficeMenuDropdownProps {
    organisationsEmployeeList: any;
    dropdownTitle: string;
}

export const OfficesDropdown = ({ organisationsEmployeeList, dropdownTitle }: OfficeMenuDropdownProps) => {
    const { t } = useTranslation();
    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData();

    const employmentMenuItems = useMemo(() => {
        let items = {};
        const allOfficesMembershipArray = getAllOfficesMembershipArray(organisationsEmployeeList);

        allOfficesMembershipArray.forEach(employeeOfficeMembership => {
            const { domain, name, officeId, role } = employeeOfficeMembership;
            items = {
                ...items,
                [`officeKey-${officeId}`]: {
                    key: `officeKey-${officeId}`,
                    label: `${name} (${t(`officeRoles.${role}`)})`,
                    domain,
                },
            };
        });
        return items;
    }, [organisationsEmployeeList, t, employeeCurrentHandledOfficeData]);

    const employmentMenu = useMemo(() => {
        const itemsSize = Object.keys(employmentMenuItems).length;

        if (itemsSize > 0) {
            const itemsGroupChildren = [];
            for (let itemKey in employmentMenuItems) {
                // @ts-ignore
                const item = employmentMenuItems[itemKey];
                const { key, label } = item;
                itemsGroupChildren.push({
                    key,
                    label,
                });
            }
            return [
                {
                    key: '1',
                    type: 'group',
                    label: t('header.officeMenu.dashboard'),
                    children: itemsGroupChildren,
                },
            ];
        }
        return [];
    }, [employmentMenuItems]);

    const handleDropdownMenuClick = (e: any) => {
        e.preventDefault();
        if (employeeCurrentHandledOfficeData?.domain) {
            const domainToRedirect = employeeCurrentHandledOfficeData.domain;
            history.push(`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domainToRedirect}`);
        }
    };

    return (
        <Dropdown.Button
            menu={{
                items: employmentMenu,
                onClick: event => {
                    const { key } = event;
                    // @ts-ignore
                    const itemData = employmentMenuItems[key];
                    const { domain } = itemData;
                    history.push(`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}`);
                },
                selectable: true,
            }}
            onClick={handleDropdownMenuClick}
            trigger={['click']}
        >
            <div className="dropdownMenu-select">
                <div className="dropdownMenu-select__name">{dropdownTitle}</div>
            </div>
        </Dropdown.Button>
    );
};
