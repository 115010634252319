import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

import { primaryColor } from '../../theme';

interface LoadingIconProps {
    fontSize?: number;
    color?: string;
}

export const LoadingIcon = ({ fontSize, color = primaryColor }: LoadingIconProps) => {
    return (
        <LoadingOutlined
            style={{
                ...(fontSize && {
                    fontSize: `${fontSize}px`,
                }),
                ...(color && {
                    color,
                }),
            }}
        />
    );
};
