import { FC, HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { IconImageName, ImageMap } from '../../../assets/imageMap';
import { IconName, IconsSvgMap } from './IconsSvgMap';

export type ExtractNativePropsFromDefault<
    T,
    TRequired extends keyof T = keyof T,
    TOptional extends keyof T = keyof T,
> = Required<Pick<T, TRequired>> & Partial<Pick<T, TOptional>>;

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'onClick'>;

interface Props {
    hoverColor?: string;
    color: string;
    height: number;
    width: number;
}

const IconStyled = styled.i<Props>(
    ({ hoverColor, color, height, width }) => css`
        display: inline-block;
        line-height: 0;
        text-align: center;
        vertical-align: middle;
        color: ${color};
        height: ${height}px;
        width: ${width}px;

        &:hover {
            color: ${hoverColor ?? undefined};
        }

        svg {
            vertical-align: top;
            width: 100%;
            height: 100%;
            font-size: inherit;
        }
    `,
);

type IconProps = NativeProps & { title?: string } & {
    icon: IconName | IconImageName | ReactNode;
    iconColor?: string;
    className?: string;
    width?: number;
    height?: number;
    size?: number;
    iconType?: 'image' | 'svg' | 'ant';
    styleProps?: object;
    hoverColor?: string;
};

export const Icon: FC<IconProps> = ({
    styleProps,
    size,
    height = 10,
    width = 10,
    iconColor = 'black',
    icon,
    iconType = 'svg',
    hoverColor,
}) => {
    if (iconType === 'image') {
        return <img src={ImageMap[icon as IconImageName]} height={height} width={width} style={{ ...styleProps }} />;
    }

    const iconHeight = size ?? height;
    const iconWidth = size ?? width;

    return (
        <IconStyled
            hoverColor={hoverColor}
            color={iconColor}
            height={iconHeight}
            width={iconWidth}
            style={{ ...styleProps }}
        >
            {iconType === 'svg' && <>{IconsSvgMap[icon as IconName]}</>}
            {iconType === 'ant' && <>{icon}</>}
        </IconStyled>
    );
};
