import * as Icons from '../../../assets/svg/icons';

export type IconName = keyof typeof IconsSvgMap;

export const IconsSvgMap = {
    TextInput: <Icons.TextInputIcon />,
    TextArea: <Icons.TextareaIcon />,
    YogaPosition: <Icons.YogaPosition />,
    Flower: <Icons.Flower />,
    Workspace: <Icons.Workspace />,
    Clients: <Icons.Clients />,
    Close: <Icons.Close />,
    Trash: <Icons.Trash />,
    WarningTriangle: <Icons.WarningTriangle />,
    Yoga: <Icons.Yoga />,
    BellNotification: <Icons.BellNotification />,
    Cookie: <Icons.Cookie />,
    HalfCookie: <Icons.HalfCookie />,
    Settings: <Icons.Settings />,
    Group: <Icons.Group />,
    User: <Icons.User />,
    UserAdd: <Icons.UserAdd />,
    UserCircle: <Icons.UserCircle />,
    UserRemove: <Icons.UserRemove />,
    ProfileCircle: <Icons.ProfileCircle />,
    UserVerified: <Icons.UserVerified />,
    Calendar: <Icons.Calendar />,
    Orders: <Icons.Orders />,
    Subscriptions: <Icons.Subscriptions />,
    Statistics: <Icons.Statistics />,
    SettingsGears: <Icons.SettingsGears />,
    EyeOpen: <Icons.EyeOpen />,
    EyeOff: <Icons.EyeOff />,
    InfoCirce: <Icons.InfoCirce />,
    Menu: <Icons.Menu />,
    QuestionMarkCircle: <Icons.QuestionMarkCircle />,
    StatsUp: <Icons.StatsUp />,
    Emoji: <Icons.Emoji />,
    Coin: <Icons.Coin />,
    Euro: <Icons.Euro />,
    CreditCard: <Icons.CreditCard />,
    PigBank: <Icons.PigBank />,
    Bank: <Icons.Bank />,
    Wallet: <Icons.Wallet />,
    Heart: <Icons.Heart />,
    Home: <Icons.Home />,
    HomeSimple: <Icons.HomeSimple />,
    LightBulb: <Icons.LightBulb />,
    CustomPlusCircleIcon: <Icons.CustomPlusCircleIcon />,
    UserScan: <Icons.UserScan />,
    Tree1: <Icons.Tree1 />,
    Tree2: <Icons.Tree2 />,
    Tree3: <Icons.Tree3 />,
    ArrowDown: <Icons.ArrowDown />,
    MoreVert: <Icons.MoreVert />,
    ClockOutline: <Icons.ClockOutline />,
    MoreVertPinAlt: <Icons.MoreVertPinAlt />,
    NavArrowRight: <Icons.NavArrowRight />,
    NavArrowLeft: <Icons.NavArrowLeft />,
    NavArrowUp: <Icons.NavArrowUp />,
    NavArrowDown: <Icons.NavArrowDown />,
    Calendar1: <Icons.Calendar1 />,
    Orders1: <Icons.Orders1 />,
    OrderHistory: <Icons.OrderHistory />,
    CreateOrder: <Icons.CreateOrder />,
    UsersSettings: <Icons.UsersSettings />,
    UserSettings: <Icons.UserSettings />,
    AnalyticsStatistics: <Icons.AnalyticsStatistics />,
    Search: <Icons.Search />,
    Check: <Icons.Check />,
    CheckCircle: <Icons.CheckCircle />,
    Trash2: <Icons.Trash2 />,
    Trash3: <Icons.Trash3 />,
    Secured: <Icons.Secured />,
    PassTickets: <Icons.PassTickets />,
    TwoTickets2: <Icons.TwoTickets2 />,
    TwoTickets3: <Icons.TwoTickets3 />,
    TwoTickets4: <Icons.TwoTickets4 />,
    TwoTickets5: <Icons.TwoTickets5 />,
    NewTicket: <Icons.NewTicket />,
    NewTicket3: <Icons.NewTicket3 />,
    Ticket: <Icons.Ticket />,
    Credits: <Icons.Credits />,
    Credits2: <Icons.Credits2 />,
    Coins: <Icons.Coins />,
    CircleR: <Icons.RCircle />,
    CircleR2: <Icons.RCircle2 />,
    RMarker: <Icons.RMarker />,
    Dashboard2: <Icons.Dashboard2 />,
};

export const allIconsNames = () => {
    const iconsArray = [];
    for (const property in IconsSvgMap) {
        iconsArray.push(property);
    }
    return iconsArray;
};
