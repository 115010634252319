import { useQuery, useReactiveVar } from '@apollo/client';
import { orgEmployeePermissions } from '@fyooga/codebook';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { useContext, useMemo } from 'react';

import { AuthContext } from '../context/AuthProvider';
import { userIdVar } from '../lib/apollo/cache';
import { getAllEmployeeOfficesAccessArray } from '../util/office';
import { useLoggedIn } from './apollo/useLoggedIn';

export const useHasUserAccessByOrganisationRole = (
    officeId: number | null,
    organisationId: number | null,
    requiredBitPermission: number,
) => {
    const isLoggedIn = useLoggedIn();
    const userId = useReactiveVar(userIdVar);

    const { loading: userOfficeEmployerDataLoading, data: userOfficeEmployerData } = useQuery(
        fyoogaQueries.getEmploymentByUserIdAndOfficeId,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                userId,
                officeId,
            },
            skip: !(userId && officeId),
        },
    );

    const hasAccess = useMemo(() => {
        if (!isLoggedIn || officeId === null || organisationId === null) {
            return false;
        }

        if (!userOfficeEmployerDataLoading && userOfficeEmployerData?.employmentByUserIdAndOfficeId) {
            const { usersOrganisationsAccessMap } = getAllEmployeeOfficesAccessArray([
                userOfficeEmployerData.employmentByUserIdAndOfficeId,
            ]);

            const orgMemberBitPermission =
                usersOrganisationsAccessMap.has(organisationId) &&
                usersOrganisationsAccessMap.get(organisationId).organisationRole
                    ? orgEmployeePermissions?.[usersOrganisationsAccessMap.get(organisationId).organisationRole]
                          ?.bitPermissions
                    : 0;
            // If the result is not 0 (zero) then the single bitmask value (user's access) is present in the sum of the bitmask (required access) and user can access
            // eslint-disable-next-line no-bitwise
            const result = orgMemberBitPermission & requiredBitPermission;
            if (result === 0) {
                return false;
            }
            return true;
        }
        return false;
    }, [requiredBitPermission, userOfficeEmployerDataLoading, userOfficeEmployerData, organisationId]);

    return hasAccess;
};
