import styled from 'styled-components';

import { primaryColor } from '../theme';

export const LinkAUnderline = styled.a<{ fontSize?: number }>`
    ${({ fontSize = 15 }) => {
        return `
                color: ${primaryColor};
                text-decoration: underline;
                line-height: 0;
                font-size: ${fontSize}px;
            
                &:hover {
                    color: ${primaryColor};
                    text-decoration: none;
                }
            `;
    }}}
`;

export const DisabledLinkLikeAUnderline = styled.div<{ fontSize?: number }>`
    ${({ fontSize = 15 }) => {
        return `
                color: grey;
                line-height: 0;
                font-size: ${fontSize}px;
                pointer-events: none;
                text-decoration: none;
                cursor: not-allowed;
            
                &:hover {
                    color: grey;
                }
            `;
    }}
`;
