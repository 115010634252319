import { FormatDateOptions } from '@formatjs/intl';

import { makeFormatter } from '../makeFormatter';
import { FormatterConfig } from '../types';

export type SimpleDatetimeFormatterDataContext<T> = {
    [K in keyof T]: T[K];
};

export interface SimpleDatetimeDataContext extends FormatDateOptions {
    withDayName?: boolean;
}

export const prepareSimpleDateTimeFormatter = (config: FormatterConfig) =>
    makeFormatter<Date, string, SimpleDatetimeFormatterDataContext<SimpleDatetimeDataContext>>((date, dataContext) => {
        const { intl } = config;
        const { withDayName = false } = dataContext;

        return intl.formatDate(date, {
            ...dataContext,
            ...(withDayName && {
                weekday: 'long',
            }),
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
    });
