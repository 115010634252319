import { colord } from 'colord';

import { COLORS_PALETTE } from '../constants';
import i18n from '../lib/i18n';

export function isDarkColor(color: string): boolean {
    return colord(color).isDark();
}

export const loadColorsToSelect = () => {
    type ResultType = {
        [key: string]: string;
    };

    const result: ResultType = {};

    Object.keys(COLORS_PALETTE).forEach(key => {
        result[key as keyof typeof result] = i18n.t(`colorScheme.${key}`);
    });

    return result;
};
