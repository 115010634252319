import { Modal as AntModal } from 'antd';
import React, { CSSProperties, ReactNode } from 'react';

export interface ModalProps {
    visible: boolean;
    onClose: (e: any) => void;
    onOk?: (e: any) => void;
    centered?: boolean;
    title?: ReactNode;
    destroyOnClose?: boolean;
    footer?: ReactNode | null;
    closable?: boolean;
    maskClosable?: boolean;
    width?: number;
    zIndex?: number;
    children: ReactNode;
    keyboard?: boolean;
    bodyStyle?: CSSProperties;
    afterClose?: () => void;
    paddingBottom?: number;
}

/**
 * https://ant.design/components/modal/
 * @param visible
 * @param onClose
 * @param onOk
 * @param children
 * @param centered
 * @param title
 * @param destroyOnClose
 * @param footer
 * @param closable
 * @param width
 * @param zIndex
 * @param keyboard
 * @param bodyStyle,
 * @param afterClose,
 * @constructor
 */
export const ModalWrapper = ({
    visible,
    onClose,
    onOk,
    children,
    centered = true,
    title,
    destroyOnClose = false,
    footer = null,
    closable = true,
    maskClosable = true,
    width,
    zIndex = 1000,
    keyboard = true,
    bodyStyle,
    afterClose,
    paddingBottom,
}: ModalProps) => {
    return (
        <AntModal
            centered={centered}
            open={visible}
            maskClosable={maskClosable}
            onCancel={onClose}
            onOk={onOk}
            destroyOnClose={destroyOnClose}
            footer={footer}
            closable={closable}
            width={width}
            keyboard={keyboard}
            zIndex={zIndex}
            // bodyStyle={bodyStyle}
            bodyStyle={{
                marginTop: 20,
                marginBottom: 20,
                ...(paddingBottom && {
                    paddingBottom,
                }),
            }}
            afterClose={afterClose}
        >
            <>
                {title && <h1>{title}</h1>}
                {children}
            </>
        </AntModal>
    );
};
