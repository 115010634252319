import { creditPassSystemStatus } from '@fyooga/codebook';

import { AddressT } from '../types/address';
import { CreditSystemInfoT, PublicCreditAndPassInfoT } from '../types/creditAndPass';
import { OfficeT, OfficeTermsT } from '../types/office';

export const isAddressNotCompletedOnboarding = (officeAddress: AddressT) => {
    if (officeAddress) {
        const { street, city, postalCode, country } = officeAddress;
        // The country is set in onboarding so we need to check if this is a fresh registration with only the country filled
        const isAddressNotCompletedOnboarding = country && !street && !postalCode && !city;
        return isAddressNotCompletedOnboarding;
    }
    return true;
};

export const isPassSystemActive = (passSystem: CreditSystemInfoT) => {
    return passSystem.systemStatus !== creditPassSystemStatus.NOT_ACTIVE;
};

export const isCreditSystemActive = (creditSystem: CreditSystemInfoT) => {
    return creditSystem.systemStatus !== creditPassSystemStatus.NOT_ACTIVE;
};

export const isCreditOrPassSystemActive = (creditAndPassInfo: PublicCreditAndPassInfoT) => {
    const creditSystemStatus = creditAndPassInfo.creditSystem.systemStatus;
    const passSystemStatus = creditAndPassInfo.passSystem?.systemStatus;

    const isCreditSystemActive = creditSystemStatus !== creditPassSystemStatus.NOT_ACTIVE;
    const isPassSystemActive = passSystemStatus !== creditPassSystemStatus.NOT_ACTIVE;

    const creditOrPassSystemActive = isCreditSystemActive || isPassSystemActive;
    return creditOrPassSystemActive;
};

export const hasOfficeDefinedTos = (termsByOfficeId?: OfficeTermsT) => {
    if (termsByOfficeId) {
        const { fileUrl, linkUrl, text } = termsByOfficeId;
        return fileUrl !== null || linkUrl !== null || text !== null;
    }
    return false;
};

export const hasOfficeDefinedGdpr = (termsByOfficeId?: OfficeTermsT) => {
    if (termsByOfficeId) {
        const { gdprLinkUrl, gdprText } = termsByOfficeId;
        return gdprLinkUrl !== null || gdprText !== null;
    }
    return false;
};

export const getOfficeContactData = (office: OfficeT) => {};

export const getAllEmployeeOfficesAccessArray = (userOrganisationsEmployee: any[]) => {
    const usersOrganisationsAccessMap = new Map();

    const allOrganisationsOfficesArrayOfArrays = userOrganisationsEmployee.map(organisationEmployee => {
        const { organisationOffices, organisationId, role: organisationRole, organisation } = organisationEmployee;
        const { organisationTariff } = organisation;

        usersOrganisationsAccessMap.set(organisationId, {
            organisationRole,
        });

        return organisationOffices.map((orgOffice: any) => {
            return {
                ...orgOffice,
                organisationTariff,
            };
        });
    });
    const allEmployeeOfficesAccessArray = allOrganisationsOfficesArrayOfArrays.flat();

    return {
        allEmployeeOfficesAccessArray,
        usersOrganisationsAccessMap,
    };
};
