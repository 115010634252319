import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentStudioName } from '../../components/ui/CurrentStudioName';
import { useEmployeeCurrentHandledOfficeData } from '../../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { currentPublicViewStudioVar } from '../../lib/apollo/cache';
import { OfficesDropdown } from './OfficesDropdown';

export const StudioSwitcher = ({ organisationsEmployeeList }: { organisationsEmployeeList: any }) => {
    const { t } = useTranslation();
    const currentPublicViewStudio = useReactiveVar<any>(currentPublicViewStudioVar);
    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData();

    return (
        <div className="studio-profile-switcher">
            {employeeCurrentHandledOfficeData ? (
                <>
                    <OfficesDropdown
                        organisationsEmployeeList={organisationsEmployeeList}
                        dropdownTitle={employeeCurrentHandledOfficeData.name}
                    />
                </>
            ) : (
                <>
                    {currentPublicViewStudio && (
                        <>
                            <CurrentStudioName
                                studioName={currentPublicViewStudio.name}
                                studioDomain={currentPublicViewStudio.domain}
                            />
                            <div className="header-separator" />
                        </>
                    )}
                    <OfficesDropdown
                        organisationsEmployeeList={organisationsEmployeeList}
                        dropdownTitle={t('header.officeMenu.chooseOffice')}
                    />
                </>
            )}
        </div>
    );
};
